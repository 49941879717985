@font-face {
    font-family: 'digital-7regular';
    src: url('digital-7-webfont.woff2') format('woff2'),
         url('digital-7-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.digits {
    font-family: "digital-7regular";
}

.v-spacer {
    height: 1000px;
}

.no-scroll {
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    overscroll-behavior: none;
}